import styled from 'styled-components';
import { Modal } from 'react-rainbow-components';
import { BACKGROUND } from '../../constants';

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 160px);
    box-sizing: border-box;
    position: relative;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
    padding: 0 2rem 0.5rem 2rem;
    margin: 0 -1.5rem;
`;

export const Gradient = styled.div`
    position: absolute;
    top: 2.7rem;
    right: 0;
    width: 100%;
    height: 3rem;
    background: linear-gradient(180deg, ${BACKGROUND} 10%, ${BACKGROUND}00 95%);
    pointer-events: none;
    z-index: 5;
`;

export const BottomGradient = styled.div`
    position: absolute;
    bottom: 0rem;
    right: 0;
    width: 100%;
    height: 5.5rem;
    background: linear-gradient(0deg, ${BACKGROUND} 2%, ${BACKGROUND}00 100%);
    pointer-events: none;
    z-index: 5;
`;

export const StyledModal = styled(Modal)`
    width: 620px;
    padding: 24px !important;
`;
