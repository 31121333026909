import {
    ReactNode,
    useCallback, useMemo, useRef, useState,
} from 'react';
import { useOutsideClick, useWindowResize } from '@rainbow-modules/hooks';
import { isEmpty } from '@rainbow-modules/validation';
import { InternalOverlay, RenderIf } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import Close from 'components/icons/close';
import ShineIcon from 'components/icons/shine';
import LoadingShape from 'components/LoadingShape';
import useFeaturesSchemas from 'data/firestore/responderfeatureschema/useCollection';
import positionResolver from './resolver';
import {
    DropDown, Header, StyledButton, StyledCheckBoxGroup,
} from './styled';

interface FeatureSchemaSelectProps {
    value?: string[] | null;
    onChange?: (values: string[] | null) => void;
    borderRadius?: 'square' | 'semi-square' | 'semi-rounded' | 'rounded';
}

const FeatureSchemaSelect = ({
    value: valueInProps = [],
    onChange = () => {},
    borderRadius = 'rounded',
}: FeatureSchemaSelectProps) => {
    const buttonRef = useRef();
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isOpen, setOpen] = useState(false);

    useWindowResize(() => setOpen(false), isOpen);
    useOutsideClick(dropdownRef, () => setOpen(false), isOpen);

    const toggle = useCallback(() => setOpen(!isOpen), [isOpen]);

    const close = useCallback(() => setOpen(false), []);

    const { data: features = [], isLoading: isLoadingFeatures } = useFeaturesSchemas();

    const selectedTypes = useMemo(() => {
        if (!valueInProps || isEmpty(valueInProps)) return 'All';
        const values = valueInProps.map(
            (featureName) => features.find(
                (feature) => feature.name === featureName,
            )?.displayName ?? featureName,
        ).map((featureName) => <b>{featureName}</b>);
        if (valueInProps.length < 3) {
            return values.reduce(
                (acc, value, index) => {
                    if (index > 0) {
                        return [...acc, ', ', value];
                    }

                    return [value];
                },
                [] as ReactNode[],
            );
        }
        return (
            <>
                {values[0]}
                {'  and '}
                {valueInProps.length - 1}
                {' '}
                others
            </>
        );
    }, [valueInProps, features]);

    const value = useMemo(
        () => {
            if (valueInProps) return valueInProps;
            return [];
        },
        [valueInProps],
    );

    const options = useMemo(
        () => [
            ...features,
            {
                displayName: 'Independent Persona',
                name: 'open',
            },
        ].map(
            (feature) => ({
                name: feature.name,
                label: feature.displayName,
                value: feature.name,
            }),
        ),
        [features],
    );

    const handleOnChange = useCallback(
        (newValues: string[]) => {
            onChange(newValues);
        },
        [onChange],
    );

    return (
        <>
            <StyledButton
                ref={buttonRef}
                variant="base"
                onClick={toggle}
                borderRadius={borderRadius}
            >
                <ShineIcon className="rainbow-m-right_xx-small" />
                <span className="rainbow-m-right_xx-small">Type:</span>
                <span>{selectedTypes}</span>
            </StyledButton>
            <InternalOverlay
                isVisible={isOpen}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                triggerElementRef={() => buttonRef.current.htmlElementRef}
                positionResolver={positionResolver}
            >
                <DropDown ref={dropdownRef}>
                    <Header>
                        Filter by type
                        <ButtonIcon
                            icon={<Close />}
                            size="small"
                            onClick={close}
                        />
                    </Header>
                    <RenderIf isTrue={isLoadingFeatures}>
                        <LoadingShape width="30%" />
                        <LoadingShape width="40%" />
                        <LoadingShape width="10%" />
                    </RenderIf>
                    <RenderIf isTrue={!isLoadingFeatures}>
                        <StyledCheckBoxGroup
                            hideLabel
                            options={options}
                            value={value}
                            onChange={handleOnChange}
                        />
                    </RenderIf>
                </DropDown>
            </InternalOverlay>
        </>
    );
};

export default FeatureSchemaSelect;
