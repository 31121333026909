import { useCallback } from 'react';
import { RenderIf } from 'react-rainbow-components';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import { useOpenModal } from '@rainbow-modules/hooks';
import OpenAI from 'components/icons/openai';
import SaraAvatar from 'components/icons/saraAvatar';
import DetailElement from 'components/OpportunityInformation/detail';
import ShineIcon from 'components/icons/shine';
import { EntityGet } from 'data/firestore/types';
import { Llm } from 'data/firestore/agent/llm/types';
import { Responderfeatureschema } from 'data/firestore/responderfeatureschema/types';
import ListTile from 'components/ListTile';
import ButtonIcon from 'components/ButtonIcon';
import Trash from 'components/icons/trash';
import Calendar from 'components/icons/calendar';
import User from 'components/icons/user';
import getDisplayName from 'data/services/profile/getDisplayName';
import AdjustmentsHorizontal from 'components/icons/adjustmentsHorizontal2';
import formatter from 'data/services/date/formatter';
import { ADD_EDIT_LLM_RESPONDER_CONFIG_MODAL } from '../../../../constants';
import useDeleteResponder from '../hooks/useDeleteResponder';
import DeleteModal from '../deleteModal';
import {
    StyledCard, Title, Description, DetailsContainer,
    Content,
} from './styled';
import NewResponderFormHeader from '../NewResponderFormHeader';

const ResponderCard = ({
    agentId,
    responder: responderData,
    feature,
}: {
    agentId: string;
    responder: EntityGet<Llm>;
    feature?: Responderfeatureschema;
}) => {
    const [openResponderConfig] = useOpenModal(ADD_EDIT_LLM_RESPONDER_CONFIG_MODAL);
    const { mutateAsync: deleteResponder } = useDeleteResponder();

    const handleEdit = useCallback(
        (responder: EntityGet<Llm>) => {
            openResponderConfig({
                title: <NewResponderFormHeader />,
                responderData: responder,
            });
        },
        [openResponderConfig],
    );

    const handleDelete = useCallback(
        async (responder: EntityGet<Llm>) => {
            const { id, name } = responder;
            const result = await confirmModal({
                header: '',
                question: <DeleteModal responderName={name} />,
                borderRadius: 'semi-square',
                okButtonLabel: 'Delete',
                cancelButtonLabel: 'Cancel',
                variant: 'destructive',
            });
            if (!result) return;

            showAppSpinner();
            try {
                await deleteResponder({
                    pathname: `/agents/${agentId}/llms/${id}`,
                });
                showAppNotification({
                    title: 'Success',
                    description: 'Responder was successfully removed',
                    icon: 'success',
                    timeout: 5000,
                });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [agentId, deleteResponder],
    );
    return (
        <StyledCard
            title={(
                <ListTile
                    icon={<SaraAvatar />}
                    title={<Title>{responderData.name}</Title>}
                    to={responderData.id}
                />
            )}
            actions={(
                <>
                    <ButtonIcon
                        icon={<AdjustmentsHorizontal />}
                        tooltip="Settings"
                        size="small"
                        borderRadius="semi-rounded"
                        onClick={() => handleEdit(responderData)}
                    />
                    <ButtonIcon
                        icon={<Trash />}
                        tooltip="Delete"
                        size="small"
                        borderRadius="semi-rounded"
                        onClick={() => handleDelete(responderData)}
                    />
                </>
            )}
        >
            <Content>
                <RenderIf isTrue={!!responderData.description}>
                    <Description>{responderData.description}</Description>
                </RenderIf>
                <DetailsContainer>
                    <DetailElement
                        icon={<ShineIcon />}
                        label="Type:"
                        value={feature?.name ?? 'Independent Persona'}
                    />
                    <DetailElement
                        icon={<OpenAI />}
                        label="Model:"
                        value={responderData.model.name}
                    />
                    <DetailElement
                        icon={<User />}
                        label="Created By:"
                        value={getDisplayName(responderData.createdBy)}
                    />
                    <DetailElement
                        icon={<Calendar />}
                        label="Created On:"
                        value={formatter.format(responderData.createdAt)}
                    />
                    <DetailElement
                        icon={<Calendar />}
                        label="Last Updated:"
                        value={formatter.format(responderData.updatedAt)}
                    />
                </DetailsContainer>
            </Content>
        </StyledCard>
    );
};

export default ResponderCard;
