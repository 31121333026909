import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import { isEmpty } from '@rainbow-modules/validation';
import { useOpenModal } from '@rainbow-modules/hooks';
import Button from 'components/Button';
import Plus from 'components/icons/plus';
import FeatureSchemaSelect from 'components/FeatureSchemaSelect';
import { orderBy, query } from 'firebase/firestore';
import FloatingFilterButton from 'components/FloatingFilterButton';
import useResponders from 'data/firestore/agent/llm/useCollection';
import useFeaturesSchemas from 'data/firestore/responderfeatureschema/useCollection';
import { ADD_EDIT_LLM_RESPONDER_MODAL } from '../../../constants';
import {
    Actions, Container, Header, ListContainer, Title,
} from './styled';
import Loading from './Loading';
import ResponderCard from './ResponderCard';
import { BottomGradient, Gradient, PageContainer } from '../styled';
import NewResponderFormHeader from './NewResponderFormHeader';
import EditResponderConfigForm from '../EditResponderConfigForm';
import useFilters from './hooks/useFilters';

const RespondersList = () => {
    const { agentId = '' } = useParams();
    const {
        searchQuery = '', typeFilter, changeTypeFilter, changeSearchQuery,
    } = useFilters();

    const {
        data: responders,
        isLoading: isLoadingResponders,
    } = useResponders(
        agentId,
        {
            disabled: !agentId,
            listQueryFn: (ref) => query(ref, orderBy('createdAt', 'asc')),
            track: [agentId],
        },
    );
    const { data: features = [], isLoading: isLoadingFeatures } = useFeaturesSchemas();
    const [openModal] = useOpenModal(ADD_EDIT_LLM_RESPONDER_MODAL);

    const isLoading = isLoadingResponders || isLoadingFeatures;

    const filteredResponders = useMemo(() => {
        const responderTypes = typeFilter ?? [];
        const hasFilters = responderTypes.length > 0 || !isEmpty(searchQuery);
        if (hasFilters) {
            return responders.filter((responder) => {
                let showResponder = true;

                if (searchQuery) {
                    const regexp = new RegExp(searchQuery, 'gi');
                    // eslint-disable-next-line max-len
                    showResponder = regexp.test(responder.name) || regexp.test(responder.description ?? '');
                }

                if (responderTypes.length) return responderTypes.includes(responder.featureSchema ?? 'open');

                return showResponder;
            });
        }
        return responders;
    }, [searchQuery, typeFilter, responders]);

    const respondersList = useMemo(() => filteredResponders.map((responder) => (
        <ResponderCard
            key={responder.id}
            agentId={agentId}
            responder={responder}
            feature={features.find((feature) => feature.name === responder.featureSchema)}
        />
    )), [filteredResponders, agentId, features]);

    return (
        <PageContainer>
            <Container>
                <Header>
                    <Title>Sara AI Hub</Title>
                    <Actions>
                        <FloatingFilterButton
                            value={searchQuery}
                            onChange={changeSearchQuery}
                        />
                        <FeatureSchemaSelect value={typeFilter} onChange={changeTypeFilter} />
                        <Button
                            variant="brand"
                            size="small"
                            borderRadius="semi-rounded"
                            onClick={() => openModal({ title: <NewResponderFormHeader /> })}
                        >
                            <Plus className="rainbow-m-right_small" />
                            New AI Persona
                        </Button>
                    </Actions>
                </Header>
                <ListContainer>
                    <RenderIf isTrue={isLoading}>
                        <Loading />
                    </RenderIf>
                    <RenderIf isTrue={!isLoading}>
                        {respondersList}
                    </RenderIf>
                </ListContainer>
            </Container>
            <Gradient />
            <BottomGradient />
            <EditResponderConfigForm />
        </PageContainer>
    );
};

export default RespondersList;
