import { useMemo } from 'react';
import { isEmpty } from '@rainbow-modules/validation';
import useSearchFilters from 'hooks/useSearchFilters';

const useFilters = () => {
    const {
        values,
        setValue: setFilterValue,
    } = useSearchFilters({ filterNames: ['search', 'type'] });

    const typeFilterValue = useMemo(
        () => {
            const { type: responderType } = values;
            if (!isEmpty(responderType)) return String(responderType).split(',');
            return null;
        },
        [values],
    );

    return useMemo(() => ({
        searchQuery: values.search as string,
        typeFilter: typeFilterValue,
        changeTypeFilter: (newFilterValue: string[] | null) => setFilterValue(
            'type',
            (
                isEmpty(newFilterValue)
                    ? null
                    : newFilterValue?.join(',')
            ),
        ),
        changeSearchQuery: (newFilterValue: string | null) => setFilterValue(
            'search',
            newFilterValue,
        ),
    }), [setFilterValue, typeFilterValue, values.search]);
};

export default useFilters;
